import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCES7OSB4hi2Vrixp9ngTWeKmQ7B-QkN34",
  authDomain: "recurzive-f9cb4.firebaseapp.com",
  projectId: "recurzive-f9cb4",
  storageBucket: "recurzive-f9cb4.appspot.com",
  messagingSenderId: "333314584686",
  appId: "1:333314584686:web:0b2a30133c161c4ca4767f"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export {db}