import React, { useState } from 'react'
import { MdOutlineClose } from "react-icons/md";
import PreLoader from './components/PreLoader'
import Header from './components/Header'
import { TracingBeam } from './components/ui/TracingBeam'
import Home from './components/Home'
import Countdown from './components/Countdown'
import About from './components/About'
import Themes from './components/Themes'
import Venue from './components/Venue'
import Sponsors from './components/Sponsors'
import FAQ from './components/FAQ'
import Queries from './components/Queries'
import Timeline from './components/Timeline'
import ImportantDates from './components/ImportantDates'
import Domains from './components/Domains'
import PrizePool from './components/PrizePool'

const HomePage = () => {
  // return(
  //     <div className="home-page">Hello</div>
  // )

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 3200);

  return (
    <div className="App bg-zinc-900 min-h-lvh max-h-full text-white font-jaro scrollbar-thin scrollbar-webkit">
      <div className="h-full w-full bg-black bg-dot-fuchsia-400/[0.3] pb-12">
        {loading ? (
          <PreLoader />
        ) : (
          <>
            <div className="bg-no-repeat bg-top h-fit w-fit bg-[url('./assets/globe.gif')]">
              <div className="z-10 backdrop-brightness-[.70]">
                <Header />
                <TracingBeam>
                  <Home />
                  <Countdown />
                  <About />
                  <Domains />
                  <Themes />
                  <PrizePool />
                  <ImportantDates />
                  <Venue />
                  <Timeline />
                  <Sponsors />
                  <FAQ />
                  <Queries />
                </TracingBeam>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HomePage;
