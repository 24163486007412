import React from "react";

function ExternalLinkIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
			<path
				fillRule="evenodd"
				d="M22 3.414L9.707 15.707a1 1 0 01-1.414-1.414L20.586 2H18a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0zM12.984 3a1 1 0 010 2H3a1 1 0 00-1 1v15a1 1 0 001 1h15.001a1 1 0 001-1.003l-.028-9.994a1 1 0 012-.006L21.001 21a3 3 0 01-3 3H3a3 3 0 01-3-3V6a3 3 0 013-3z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}

export default ExternalLinkIcon;
