import React, { Fragment } from "react";

const timeline = [
  {
    heading: "Check-In and Registration Starts",
    time: "8:30 AM",
    description: "",
  },
  {
    heading: "Inauguration Ceremony",
    time: "9:00 AM",
    description: "",
  },
  {
    heading: "Coding Starts!",
    time: "9:30 AM",
    description: "",
  },
  // {
  //     heading: 'Lunch',
  //     time: '1:00 PM',
  //     description: 'sto commodo vitae. Morbi fermentum nec quam sit amet mollis. Etiam rhoncus semper sem, quis molestie mauris elementum a. Suspendisse egestas finibus nisl, non vulputate elit hendrerit sodales. Sed sed elit massa. Fusce molestie, erat et lobortis pharetra, est ante hendrerit lorem, at iaculis mi velit ac odio. Phasellus nec condimentum est. Duis sem orci, congue ac suscipit ac, imperded laoreet diam'
  // },
  {
    heading: "Refreshment time",
    time: "3:00 PM",
    description: "",
  },
  // {
  //     heading: 'Snacks',
  //     time: ' 5:00 PM',
  //     description: 'sto commodo vitae. Morbi fermentum nec quam sit amet mollis. Etiam rhoncus semper sem, quis molestie mauris elementum a. Suspendisse egestas finibus nisl, non vulputate elit hendrerit sodales. Sed sed elit massa. Fusce molestie, erat et lobortis pharetra, est ante hendrerit lorem, at iaculis mi velit ac odio. Phasellus nec condimentum est. Duis sem orci, congue ac suscipit ac, imperded laoreet diam'
  // },
  {
    heading: "First Round of Mentor Evaluation",
    time: "6:00 PM",
    description: "",
  },
  // {
  //     heading: 'Dinner',
  //     time: '8:30 PM',
  //     description: 'sto commodo vitae. Morbi fermentum nec quam sit amet mollis. Etiam rhoncus semper sem, quis molestie mauris elementum a. Suspendisse egestas finibus nisl, non vulputate elit hendrerit sodales. Sed sed elit massa. Fusce molestie, erat et lobortis pharetra, est ante hendrerit lorem, at iaculis mi velit ac odio. Phasellus nec condimentum est. Duis sem orci, congue ac suscipit ac, imperded laoreet diam'
  // },
  {
    heading: "Second Round of Mentor Evaluation",
    time: "12:00 AM",
    description: "",
  },
  // {
  //     heading: 'Breakfast',
  //     time: '12:00 AM',
  //     description: 'sto commodo vitae. Morbi fermentum nec quam sit amet mollis. Etiam rhoncus semper sem, quis molestie mauris elementum a. Suspendisse egesta\rit lorem, at iaculis mi velit ac odio. Phasellus nec condimentum est. Duis sem orci, congue ac suscipit ac, imperded laoreet diam'
  // },
  {
    heading: "Coding Stops",
    time: "9:15 AM",
    description: "",
  },
  {
    heading: "Devfolio Submissions",
    time: "9:30 AM",
    description: "",
  },
  {
    heading: "Judging Round Commences",
    time: "10:00 AM",
    description: "",
  },
  {
    heading: "Grand Jury Round",
    time: "12:00 PM",
    description: "",
  },
  {
    heading: "Prize Distribution and Closing Ceremony",
    time: "1:00 PM",
    description: "",
  },
];

const Circle = () => {
  return (
    <div className="rounded-full border-4 w-4 h-4 -m-2 border-blue-400 z-10 bg-white"></div>
  );
};

const Pillar = () => {
  return (
    <div className="border-4 w-0 min-h-36 h-full border-slate-200/60"></div>
  );
};

const EventCard = ({ event }) => {
  return (
    <div className="flex flex-col gap-y-2 p-4 bg-slate-500/30 mb-10 h-fit font-jaro w-full sm:w-96 rounded-xl">
      <p className="text-xl sm:text-2xl font-bold">{event.heading}</p>
      <p className="italic">{event.time}</p>
      <p className="text-sm sm:text-base">{event.description}</p>
    </div>
  );
};

const Timeline = () => {
  return (
    <div id="timeline" className="flex flex-col gap-20 mt-10 px-4 sm:px-0">
      <h2 className="text-3xl font-bold">TIMELINE</h2>
      <div className="flex flex-col justify-center my-4">
        {timeline.map((event, index) => (
          <Fragment key={index}>
            {index % 2 === 0 ? (
              <div className="hidden md:grid md:grid-cols-[1fr_auto_1fr] justify-stretch gap-x-4 mx-auto">
                <EventCard event={event} />
                <div className="flex flex-col items-center">
                  <Circle />
                  {index !== timeline.length - 1 && <Pillar />}
                </div>
                <div></div>
              </div>
            ) : (
              <div className="hidden md:grid md:grid-cols-[1fr_auto_1fr] justify-stretch gap-x-4 mx-auto">
                <div></div>
                <div className="flex flex-col items-center">
                  <Circle />
                  {index !== timeline.length - 1 && <Pillar />}
                </div>
                <EventCard event={event} />
              </div>
            )}
            <div className="md:hidden grid grid-cols-[auto_1fr] justify-stretch gap-x-4">
              <div className="flex flex-col items-center">
                <div
                  className={`${
                    index === timeline.length - 1
                      ? `relative left-1 z-10`
                      : `z-10`
                  }`}
                >
                  <Circle />
                </div>
                {index !== timeline.length - 1 && <Pillar />}
              </div>
              <EventCard event={event} />
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
