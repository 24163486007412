import gsap from "gsap";

// Declare a general timeline to use in all the animation functions.

const tl = gsap.timeline();

// Preloader Animation
export const preLoaderAnim = () => {
    tl.to(".postload", {
        duration: 0.1,
        css: { overflowY: "hidden" },
        ease: "power3.inOut",
    })
        // .to(".landing", {
        //     duration: 0.05,
        //     css: { overflowY: "hidden", height: "90vh" },
        // })
        .to(".texts-container", {
            duration: 0,
            opacity: 1,
            ease: "Power3.easeOut",
        })
        .from(".texts-container span", {
            duration: 1.0,
            delay: 0,
            y: 70,
            skewY: 10,
            stagger: 0.2,
            ease: "Power3.easeOut",
        })
        .to(".texts-container span", {
            duration: 0.6,
            // y: 70,
            skewY: -10,
            stagger: 0.1,
            ease: "Power3.easeOut",
        })
        .to(".postload", {
            duration: 0.1,
            css: { overflowY: "scroll" },
            ease: "power3.inOut",
        })
        .to(
            ".preloader",
            {
                duration: 0.8,
                height: "0vh",
                ease: "Power3.easeOut",
                // onComplete: window.innerWidth < 763 && mobileLanding(),
                // y:"-=2"
            },
        )
        // .to(".preloader", {
        //     duration: 0,
        //     css: { display: "none" },
        // });
};