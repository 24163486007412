import React from 'react'

const PrizePool = () => {

    return (
        <div id='prizePool' className='flex flex-col py-24'>
            <h2 className='text-3xl font-bold mb-5'>PRIZE POOL</h2>
            <div className='bg-slate-500/30 p-10 rounded-xl'>
                <h3 className='text-2xl text-blue-500 uppercase'><span className='text-4xl'>70,000 INR</span>&nbsp; Prize Pool:</h3>
                <div className='pt-4 text-3xl '>
                <span className='text-yellow-400'>GRAND PRIZE:</span>
                    <ul className='text-xl flex flex-col gap-y-3'>
                        <li className='flex flex-col sm:flex-row'>
                            <span className='text-2xl'>WINNERS&nbsp;:&nbsp;</span><span className='text-2xl'>35,000 INR</span>
                        </li>
                        <li className='flex flex-col sm:flex-row'>
                            <span className='text-2xl'>RUNNERS-UP&nbsp;:&nbsp;</span><span className='text-2xl'>15,000 INR</span>
                        </li>
                    </ul>
                </div>
                <div className='pt-4 text-3xl'>
                <span className='text-yellow-400'>TRACK PRIZE:</span>
                    <ul className='text-xl flex flex-col gap-y-3'>
                        <li className='flex flex-col sm:flex-row'>
                            <span className='text-2xl'>5,000 INR EACH</span>
                        </li>
                    </ul>
                </div>
                {/* <ul className='list-disc p-4 text-lg'>
                    <li>
                        Compete for a chance to win your share of an impressive 70,000 prize pool.
                    </li>
                    <li>
                        Prizes will be awarded to the top-performing teams across multiple
                        categories, recognizing outstanding innovation, technical excellence, and
                        impactful solutions.
                    </li>
                    <li>
                        Opportunity to be selected at incubation center of IEDC.
                    </li>
                </ul> */}
            </div>
        </div>
    )
}

export default PrizePool