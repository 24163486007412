import React from "react";
// import { RxDotFilled } from 'react-icons/rx'
// import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs'
import Marquee from "react-fast-marquee";

const Sponsors = () => {
  const sponsors = [
    {
      src: "/assets/Devfolio_Logo.png",
      alt: "DEVFOLIO LOGO",
    },
    {
      src: "/assets/Polygon_Logo.png",
      alt: "POLYGON LOGO",
    },
    {
      src: "/assets/ETHIndia_Logo.png",
      alt: "ETHINDIA LOGO",
    },
    {
      src: "/assets/Derbi_Logo.png",
      alt: "DERBI LOGO",
    },
    {
      src: "/assets/IEDC_Logo.png",
      alt: "IEDC LOGO",
    },
    {
      src: "/assets/SagarHospitals_Logo.jpg",
      alt: "SAGAR HOSPITALS",
    },
    {
      src: "/assets/IBITFBhilai_Logo.jpg",
      alt: "IBITF Bhilai",
    },
  ];

  return (
    <div id="sponsors" className="relative flex flex-col gap-20 my-10">
      <h2 className="text-3xl font-bold">SPONSORS</h2>
      <Marquee speed={200} pauseOnHover>
        {sponsors.map((slide, index) => (
          <img
            key={index}
            src={slide.src}
            alt={slide.alt || "SPONSOR"}
            className={
              index > 2
                ? `md:h-32 h-20 mx-4 inline-block rounded-xl`
                : `md:h-32 h-20 mx-4 inline-block`
            }
          />
        ))}
      </Marquee>
      <Marquee direction="right" speed={200} pauseOnHover>
        {sponsors.map((slide, index) => (
          <img
            key={index}
            src={slide.src}
            alt={slide.alt || "SPONSOR"}
            className={
              index > 2
                ? `md:h-32 h-20 mx-4 inline-block rounded-xl`
                : `md:h-32 h-20 mx-4 inline-block`
            }
          />
        ))}
      </Marquee>
    </div>
  );
};

export default Sponsors;
