import React, { useState } from 'react'
import { FaCaretDown } from 'react-icons/fa'

const FAQ = () => {

  const [show, setShow] = useState(null)
  const [viewAll, setViewAll] = useState(false)

  const faqs = [
    {
      question: 'What is Recurzive?',
      answer: `Recurzive is a 24-hour hackathon organized by ByteXync and OSCode at DSCE, aimed at fostering innovation among young developers and tech enthusiasts. The event includes an intensive coding session where participants collaborate to develop innovative solutions to real-world challenges.`
    },
    {
      question: "When and where is Recurzive happening?",
      answer: `Recurzive will take place on July 4th and 5th. The exact venue will be shared with registered participants closer to the event date.`
    },
    {
      question: "Who can participate in Recurzive?",
      answer: "The hackathon is open exclusively to students."
    },
    {
      question: `How can I register for Recurzive`,
      answer: `Registration details and forms are available on our website. Participants will apply with their ideas on Devfolio. Based on these submissions, approximately 100 teams will be selected to participate. Selected teams will be notified around 5 days before the event.`
    },
    {
      question: "What are the themes and domains for the hackathon? ",
      answer: `Participants will work on solutions within four themes: Finance Healthcare, Smart City/Transportation, and Social Media Platform. The technological domains include AR/VR, Blockchain, ML, and Gaming.`
    },
    {
      question: " Is there a participation fee?",
      answer: "The registration fee is 400 INR per team."
    },
    {
      question: "What should I bring to the hackathon?",
      answer: "Bring your laptop, chargers, and any other hardware you need for development. We also recommend bringing personal items like water bottles, snacks, and a change of clothes."
    },
    {
      question: "Will there be food provided?",
      answer: "Yes, we will provide meals and snacks throughout the event, including lunch, dinner, and midnight snacks."
    },
    {
      question: "Can I form a team before the hackathon?",
      answer: "Yes, teams must be formed before the event. Teams can have up to 4 members. Make sure to coordinate with your team members and register as a team. Please ensure all team names are respectful; disrespectful team names may result in disqualification."
    },
    {
      question: " What is the prize for the winners?",
      answer: "The total prize pool for Recurzive is 70,000 INR, which will be distributed among the winning teams."
    },
    {
      question: "How will the projects be evaluated?",
      answer: "Projects will be evaluated in multiple rounds by mentors and judges based on innovation, feasibility, impact, and presentation."
    },
  ]

  const displayableFaqs = viewAll ? faqs : faqs.splice(0,3)

  return (
    <div id='faq' className='relative flex flex-col gap-20 mt-10 py-24'>
      <h2 className='text-3xl font-bold'>FAQs</h2>
      <div className='border-2 rounded-lg border-white font-jaro text-xl'>
        {
          displayableFaqs.map((faq, index) => (
            <div key={index} className='m-2 border-2 rounded-lg border-white bg-slate-500/30'>
              <div className='flex justify-between cursor-pointer items-center' onClick={() => setShow(show !== index || show === null ? index : null)}>
                <span className='m-2'>
                  Q:&nbsp;&nbsp;{faq.question}
                </span>
                <FaCaretDown className={show === index ? `m-2 rotate-180`:`m-2`} />
              </div>
              {
                show === index &&
                <p className=' border-t-2 border-white m-2 pt-2'>
                  A:&nbsp;&nbsp;{faq.answer}
                </p>
              }
            </div>
          ))
        }
        <button 
        className='m-2 mt-0 border-2 rounded-lg border-white bg-slate-500/30 font-jaro px-2' 
        onClick={() => setViewAll(!viewAll)}
      >
        {viewAll ? 'Show Less' : 'View All'}
      </button>
      </div>
      
    </div>
  )
}

export default FAQ