import Brochure from './Brochure';
import './index.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from './HomePage';

// ByteXync theme color: "text-white bg-gradient-to-bl from-pink/rose-950 to-fuchsia-950"

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/brochure" element={<Brochure />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;