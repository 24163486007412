import React from 'react'

const About = () => {

    return (
        <>
            <div id='about' className='flex justify-between items-center gap-20 mt-20 sm:mt-32 mb-5 py-24'>
                <div className='w-fit flex flex-col md:flex-row justify-between items-center p-10 bg-slate-500/30 rounded-xl'>
                    <div className='md:w-3/5'>
                        <h2 className='text-3xl font-bold'>About Recurzive</h2>
                        <br />
                        <p>"Recurzive" is a premier 24-hour hackathon organized by ByteXync and OSCode at DSCE,
                            designed to inspire and challenge young innovators and tech enthusiasts. This event aims to
                            bring together over 500 participants from diverse backgrounds to ideate, collaborate, and
                            develop groundbreaking solutions to real-world problems. By focusing on key themes like
                            Finance, Healthcare, Smart City/Transportation, and Social Media Platforms, Recurzive
                            encourages participants to push the boundaries of technology and innovation. The
                            hackathon provides a dynamic platform for creativity, critical thinking, and entrepreneurial
                            spirit, supported by expert mentorship, industry insights, and competitive rewards.</p>
                    </div>
                    <div className='flex justify-center items-center rounded-full'>
                        <img src='/assets/RECURZIVE_LOGO-no-bg.png' alt='DP' className='rounded-full w-56 md:w-72' />
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <div className='bg-slate-500/30 p-4 rounded-xl w-3/4 py-10'>
                    <p className='text-xl font-jaro text-center'>BROUGHT TO YOU BY:</p>
                    <p className='text-center text-3xl font-bold font-jaro'>DAYANANDA SAGAR COLLEGE OF ENGINEERING</p>
                    <p className='text-center text-xl font-jaro'>DEPARTMENT OF COMPUTER SCIENCE AND ENGINEERING</p>
                    <div className='flex flex-wrap justify-around mt-10'>
                        <div className='flex flex-col justify-center items-center'>
                            <img src='https://storage.googleapis.com/ezap-prod/colleges/5011/dayanand-sagar-college-of-engineering-logo.png' alt='DEPT OF CSE, DSCE' className='w-28 rounded-full' />
                            <p className='p-1 text-lg'>DEPT OF CSE, DSCE</p>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <img src='/assets/BX.jpg' alt='ByteXync, DSCE' className='w-28 rounded-full' />
                            <p className='p-1 text-lg'>ByteXync DSCE</p>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <img src='/assets/OIP.jpeg' alt='OSCode DSCE' className='w-28 rounded-full' />
                            <p className='p-1 text-lg'>OSCode DSCE</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About