import React from 'react'

const Domains = () => {

  const cardClass = 'w-fit flex flex-col justify-center items-center rounded-lg';
  const cardTitle = 'w-full py-2 text-xl font-bold text-cyan-500 text-center rounded-b-lg px-2';

  return (
    <div id='themes' className='flex flex-col gap-20 mt-10 pt-24 pb-10'>
      <h2 className='text-3xl font-bold'>DOMAINS</h2>
      <div className='flex flex-wrap justify-around'>
        <div className={cardClass}>
          <img src='https://showmevirtual.com/wp-content/uploads/2018/04/SMV-Cardboard-LoadingIcon-2.gif' alt='AR/VR' className='w-44' />
          <p className={cardTitle}>AR/VR</p>
        </div>
        <div className={cardClass}>
          <img src='https://media.tenor.com/hpIgU8KoB6cAAAAi/mobile-bitcoin.gif' alt='Blockchain' className='w-44' />
          <p className={cardTitle}>Web3</p>
        </div>
        <div className={cardClass}>
          <img src='https://miro.medium.com/v2/resize:fit:828/format:webp/0*Yb_BsikIKFAtuKj9.gif' alt='Machine Learning' className='size-44 rounded-full' />
          <p className={cardTitle}>AI/ML</p>
        </div>
        <div className={cardClass}>
          <img src='https://media4.giphy.com/media/5YpDAIRBS2xJMBoF2p/giphy-preview.gif' alt='Gaming' className='w-44' />
          <p className={cardTitle}>Gaming</p>
        </div>
      </div>
    </div>
  )
}

export default Domains