import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
// import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

function ImportantDates() {

    const importantDates = [
        {
            date: 'May 25',
            event: 'Registration starts'
        },
        {
            date: 'June 24',
            event: 'Registration and idea submission closes'
        },
        {
            date: 'June 27',
            event: 'List of selected participants announced'
        },
        {
            date: 'July 4',
            event: 'Hackathon day!!!'
        },
    ]

  return (
    <div>
        <h2 className='text-3xl font-bold mb-5'>IMPORTANT DATES</h2>
        <div className='mb-0 p-5 pb-0 rounded-xl relative bg-slate-500/30'>
        <Timeline sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
                    {importantDates.map((item, index) => (
                        <TimelineItem key={index}>
                            <TimelineSeparator>
                                {index%2 !==0 ?<TimelineDot variant="outlined" color="primary"/>: <TimelineDot variant="outlined" color="secondary"/>}
                                {index !== importantDates.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent className='flex gap-2'><p className='text-xl font-bold font-jaro text-blue`-500'>{item.date}&nbsp;:&nbsp;</p><p className='text-xl font-jaro'> {item.event}</p></TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
        </div>
    
    </div> 
  )
}

export default ImportantDates