import React from 'react'

const Themes = () => {

  const cardClass = 'w-fit flex flex-col justify-center items-center rounded-lg';
  const cardTitle = 'w-full py-2 text-xl font-bold text-cyan-500 text-center rounded-b-lg px-2';

  return (
    <div id='themes' className='flex flex-col gap-20 mt-10 py-24'>
      <h2 className='text-3xl font-bold'>THEMES</h2>
      <div className='flex flex-wrap justify-around'>
        <div className={cardClass}>
          <img src='https://media1.giphy.com/media/alltI0QXBjCt7YLYzN/giphy.gif?cid=6c09b9523q53cvqmdyc5n3c8zls9l7bwrrhzohh767znv4wc&ep=v1_internal_gif_by_id&rid=giphy.gif' alt='Finance' className='w-44' />
          {/* <img src='https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iUaBSLsLopc4/v0/-999x-999.gif' alt='Finance' className='w-44' /> */}
          {/* <img src='https://cdn.pixabay.com/animation/2022/10/13/01/37/01-37-51-884_512.gif' alt='Finance' className='w-44' /> */}
          <p className={cardTitle}> Finance</p>
        </div>
        <div className={cardClass}>
          <img src='https://timengo.com/wp-content/uploads/2020/07/Animeret-grafik-city.gif' alt='Smart City' className='w-44' />
          {/* <img src='https://images.forbes.com/brandvoice-specialfeatures/dell-2016-1/images/Traffic-Icon.gif' alt='Smart City' className='w-44' /> */}
          {/* <img src='https://media0.giphy.com/media/7Hj4pcZHZudRkMpOkh/giphy.gif' alt='Smart City' className='w-44' /> */}
          <p className={cardTitle}>Smart City - Sustainability and Transportation</p>
        </div>
        <div className={cardClass}>
          {/* <img src='https://media.tenor.com/95DC83MFflsAAAAi/healthcare-healthcare-for-all.gif' alt='Healthcare' className='w-44' /> */}
          <img src='https://megdap.com/wp-content/uploads/2021/01/health-care-1.gif' alt='Healthcare' className='w-44' />
          <p className={cardTitle}>Healthcare</p>
        </div>
        <div className={cardClass}>
          {/* <img src='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYXdwbjFvNDhibjlnbnV4OW4xbXBsYWVtZmlleXpkZm5ybTBqNDQ0YyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3GSoFVODOkiPBFArlu/giphy.gif' alt='Social Media' className='w-44' /> */}
          <img src='https://getnimnim.com/blog/wp-content/uploads/2023/12/NimNimsSocialMediaMission.gif' alt='Social Media' className='w-44' />
          <p className={cardTitle}>Social Media</p>
        </div>
        {/* <div className={cardClass}>
          <img src='https://media.tenor.com/7fw1qgKb4MkAAAAj/netherite-pickaxe-minecraft.gif' alt='Mining' className='w-44' />
          <p className={cardTitle}>Mining</p>
        </div> */}
      </div>
    </div>
  )
}

export default Themes