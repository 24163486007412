import React, { useEffect, useRef, useState } from 'react';

const Countdown = () => {
    let interval = useRef();

    const [countdown, setCountdown] = useState({ months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [countdownText, setCountdownText] = useState('REMAINING')
    const target = new Date('July 4, 2024 09:30:00').getTime();

    useEffect(() => {
        startTimer();
        return () => clearInterval(interval.current); // Clear interval on component unmount
    }, []);

    const startTimer = () => {
        interval.current = setInterval(() => {
            const current = new Date().getTime();
            const distance = target - current;

            if (distance <= 0) {
                clearInterval(interval.current);
                setCountdownText('ENJOY CODING')
            } else {
                const monthsleft = Math.floor(distance / (1000 * 60 * 60 * 24 * 30)) % 12;
                const daysleft = Math.floor(distance / (1000 * 60 * 60 * 24)) % 30;
                const hoursleft = Math.floor(distance / (1000 * 60 * 60)) % 24;
                const minutesleft = Math.floor(distance / (1000 * 60)) % 60;
                const secondsleft = Math.floor(distance / 1000) % 60;

                setCountdown({
                    months: monthsleft,
                    days: daysleft,
                    hours: hoursleft,
                    minutes: minutesleft,
                    seconds: secondsleft,
                });
            }
        }, 1000);
    };

    return (
        <div className="flex flex-col justify-center items-center mb-20 sm:my-24">
            <div className="flex flex-wrap justify-center gap-1 md:gap-x-2 font-jaro text-xl sm:text-3xl">
                {countdown.months > 0 && (
                    <div className="bg-slate-500/30 p-2 rounded-xl flex flex-col justify-center items-center w-fit md:w-28">
                        <p className="text-center">{countdown.months}</p>
                        <p className="text-center">MONTH{countdown.months !== 1 && <span>S</span>}</p>
                    </div>
                )}
                {countdown.days > 0 && (
                    <div className="bg-slate-500/30 p-2 rounded-xl flex flex-col justify-center items-center w-fit md:w-28">
                        <p className="text-center">{countdown.days}</p>
                        <p className="text-center">DAY{countdown.days !== 1 && <span>S</span>}</p>
                    </div>
                )}
                <div className="bg-slate-500/30 p-2 rounded-xl flex flex-col justify-center items-center w-fit md:w-28">
                    <p className="text-center">{countdown.hours}</p>
                    <p className="text-center">
                        HOUR{countdown.hours !== 1 && <span>S</span>}
                    </p>
                </div>
                <div className="bg-slate-500/30 p-2 rounded-xl flex flex-col justify-center items-center w-fit md:w-28">
                    <p className="text-center">{countdown.minutes}</p>
                    <p className="text-center">
                        MINUTE{countdown.minutes !== 1 && <span>S</span>}
                    </p>
                </div>
                <div className="bg-slate-500/30 p-2 rounded-xl flex flex-col justify-center items-center w-fit md:w-28">
                    <p className="text-center">{countdown.seconds}</p>
                    <p className="text-center">
                        SECOND{countdown.seconds !== 1 && <span>S</span>}
                    </p>
                </div>
            </div>
            <p className="text-2xl md:text-3xl font-jaro font-bold mt-4">{countdownText}!</p> 
        </div>
    );
};

export default Countdown;
