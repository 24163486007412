import React, { useEffect } from 'react'
import { preLoaderAnim } from './animation'

const PreLoader = () => {

    useEffect(() => {
        preLoaderAnim()
    }, [])


    return (
        <div className='postload h-screen w-full fixed bottom-0 left-0 z-55 overflow-hidden text-white bg-black flex justify-center items-center gap-x-4 font-jaro text-4xl'>
            <div className='preloader texts-container flex justify-between items-center overflow-hidden h-60 w-96'>
                <span>HACK</span>
                <span>IN-FINITE</span>
                <span>TIME!</span>
            </div>
            {/* <div className='postload flex justify-center items-center absolute landing h-full w-full bg-zinc-950'>
                <span className='bg-zinc-950'>RECURZIVE</span>
            </div> */}
        </div>
    )
}

export default PreLoader