import React from 'react';

const Header = () => {
    // const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(0);

    // const toggleMobileMenu = () => {
    //     setIsMobileMenuOpen(!isMobileMenuOpen);
    // };

    return (
        <header>
            <nav className="flex w-full justify-around items-center py-6 md:py-8 mb-10 bg-zinc-950/80">
                {/* Logo */}
                <span className="text-5xl text-fuchsia-400 font-jaro">
                    <img src='/assets/RECURZIVE_LOGO-no-bg.png' alt='RECURZIVE LOGO' className='w-12' />
                </span>

                {/* Menu items */}
                <ul className={`hidden md:flex flex-col md:flex-row justify-center items-center gap-10 lg:gap-24 font-jaro transition-all duration-300`}>
                    <li><a href="#about" className="text-2xl font-bold">ABOUT</a></li>
                    <li><a href="#themes" className="text-2xl font-bold">THEMES</a></li>
                    <li><a href="#sponsors" className="text-2xl font-bold">SPONSORS</a></li>
                    <li><a href="#faq" className="text-2xl font-bold">FAQ</a></li>
                    <li><a href="#venue" className="text-2xl font-bold">VENUE</a></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
