import React from 'react'

const Brochure = () => {
  return (
    <div>
        <iframe src='/assets/RecurziveBrochure.pdf' className='h-screen w-screen' title='Recurzive-Brochure'></iframe>
    </div>
  )
}

export default Brochure