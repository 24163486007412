import React, { useEffect, useRef } from "react";
import ExternalLinkIcon from "./icons/ExternalLinkIcon";

const Home = () => {
  const buttonRef = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apply.devfolio.co/v2/sdk.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-5 md:py-24 py-10 mb-14 justify-between items-center">
        <div className="flex flex-col gap-5 text-center lg:text-left">
          <h1 className="text-6xl md:text-7xl text-[#5c3697] font-bold uppercase tracking-wider">
            Recurzive
          </h1>
          <span className="md:mb-16 mb-2 text-lg md:text-xl">
            HACK IN-FINITE TIME
          </span>
          <span className="text-lg md:text-xl font-bold text-blue-500">
            4 - 5 July 2024
          </span>
          <span className="text-lg md:text-xl font-bold">
            24 Hour Hackathon
          </span>
          <div
            ref={buttonRef}
            class="apply-button"
            data-hackathon-slug="recurzive"
            data-button-theme="light"
            style={{ height: 44 + "px", width: 312 + "px" }}
          ></div>
          {/* <button ref={buttonRef} className="w-fit p-2 bg-blue-600 rounded-lg flex justify-center items-center transition duration-700 hover:scale-110 mx-auto lg:mx-0">
                        Register With &nbsp;
                        <img src="https://hacknovate-ef339.web.app/images/sponsor/Devfolio.png" alt="Devfolio" className="w-20 md:w-24 inline-block" />
                    </button> */}
          <a href="/brochure" target="_blank">
            <button
              className={`p-2 flex items-center justify-center bg-slate-300/30 rounded-md mx-auto lg:mx-0 w-[318px]`}
            >
              Brochure&nbsp;
              <ExternalLinkIcon className="w-4 mx-2 fill-slate-200" />
            </button>
          </a>
        </div>
        <div className="flex flex-col gap-10 justify-center items-center">
          <div className="rounded-full flex items-center justify-center">
            <img
              src="/assets/RECURZIVE_LOGO-no-bg.png"
              alt="Recurzive"
              className="w-56 md:w-80 rounded-full"
            />
          </div>
          <div className="flex gap-5 md:gap-10">
            <a
              href="https://instagram.com/recurzive_"
              target="_blank"
              rel="noreferrer"
              className="w-16 p-3 md:p-4 rounded-full bg-slate-500/30 cursor-pointer transition duration-700 hover:bg-pink-400"
            >
              <img
                src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/instagram-white-icon.png"
                alt="Recurzive"
              />
            </a>
            <a
              href="https://discord.gg/ckZD6fW6X8"
              className="w-16 p-3 md:p-4 rounded-full bg-slate-500/30 cursor-pointer transition duration-700 hover:bg-purple-500"
            >
              <img
                src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-white-icon.png"
                alt="Discord"
              />
            </a>
            <a
              href="mailto:recurzive.dsce@gmail.com"
              className="w-16 p-3 md:p-4 rounded-full bg-slate-500/30 cursor-pointer transition duration-700 hover:bg-blue-500"
            >
              <img src="https://logodix.com/logo/610924.png" alt="Mail" />
            </a>
          </div>
        </div>
      </div>
      {/* <p className="font-jaro font-bold text-5xl text-center">COMING SOON...</p> */}
    </>
  );
};

export default Home;
